<template>

  <div class="cbox">
    <div class="headbar">
      <div style="float: right;">
        <span>关键字：</span>
        <el-input style="width:200px;margin-right: 10px;" v-model="searchForm.issue" size="small" placeholder="请输入关键词"></el-input>
        <span>状态：</span>
        <el-select style="width:120px" v-model="searchForm.dis_flag" size="small" placeholder="请选择">
          <el-option label="启用" :value="0"></el-option>
          <el-option label="禁用" :value="1"></el-option>
        </el-select>

        <el-button size="small" icon="el-icon-search" @click="onSearch" plain type="primary" style="margin-left: 10px;">
          查询
        </el-button>
       
        <el-button size="small" type="primary" icon="el-icon-plus" @click="onAdd">添加</el-button>


      </div>
      <div style="padding-top: 14px;">
        <el-breadcrumb>
          <el-breadcrumb-item>咨询管理</el-breadcrumb-item>
          <el-breadcrumb-item>智能问答</el-breadcrumb-item>

        </el-breadcrumb>
      </div>

    </div>
    <div class="bcontent">





      <el-row type="flex" :gutter="16" style="margin-top: 20px">
        <el-col :span="24" class="tabContainer">
          <el-tabs v-model="activeName" type="border-card" style="height: 100%;">
            <el-tab-pane v-for="item in tabMapOptions" :key="item.key" :label="item.label" :name="item.key">
              <el-table :data="tableData" stripe size="mini" style="width: 100%;margin-bottom: 20px;" border>
                <el-table-column prop="index" width="55" label="序号" align="center">
                  <template slot-scope="scope">
                    {{ scope.$index + 1 + ((page.pageIndex - 1) * page.pageSize) }}
                  </template>
                </el-table-column>
                <el-table-column prop="question" label="问题标题">
                  <template slot-scope="scope">
                    <div class="flexStart">
                      <div style="width: 40px;height: 40px">
                        <img :src="'./img/advice_01.png'" alt="" style="width: 100%;height: 100%;">
                      </div>
                      <div class="flexCloumn" style="margin-left: 20px">
                        <div style=" color: #9a9a9a;">
                          {{ scope.row.issue }}
                        </div>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="cdate" label="创建时间" width="140" align="center" />
                <el-table-column prop="cdate" label="是否启用" width="80" align="center">
                  <template slot-scope="scope">
                    <div v-if="scope.row.dis_flag == 0" style="color: #67c23a;">启用</div>
                    <div v-if="scope.row.dis_flag == 1" style="color: #f56c6c;">禁用</div>
                  </template>
                </el-table-column>
                <el-table-column prop="heat" label="热度" width="80" align="center">
                  <template slot-scope="scope">
                    <div class="flexCenter">
                      <img :src="'./img/advice_02.png'" alt="" style="width: 20px;height: 20px;vertical-align: middle;">
                      <span style=" color: #9a9a9a;margin-left: 8px;vertical-align: middle;">
                        {{ scope.row.heat }}
                      </span>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column label="操作" width="140" align="center">
                  <template slot-scope="scope">
                    <el-button icon="el-icon-chat-line-round" @click="onModify(scope.row)" type="text" size="small">修改
                    </el-button>
                    <el-button icon="el-icon-delete" @click="onDel(scope.row)" type="text" size="small"
                      style="color: #f56c6c;">删除
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination @current-change="handlePageChange" :current-page="page.pageIndex"
                :page-size="page.pageSize" :total="page.total" @size-change="handleSizeChange"
                :page-sizes="page.pageSizes" layout="total, prev, pager, next, sizes, jumper">
              </el-pagination>
            </el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>

    </div>

    <el-dialog custom-class="width_800 cus_dialog" :title="modalTitle" :visible.sync="visible"
      :close-on-click-modal="false">
      <el-form :model="editForm" label-width="80px" :rules="rules" ref="adminUserInfo">
        <el-form-item label="问题标题" prop="issue">
          <el-input v-model.trim="editForm.issue" type="textarea" :autosize="{ minRows: 4, maxRows: 10 }"
            placeholder="请输入问题" style="width: 100%" />
        </el-form-item>
        <el-form-item label="关联词语" prop="issue_remark">
          <el-input v-model.trim="editForm.issue_remark" type="textarea" :autosize="{ minRows: 2, maxRows: 6 }"
            placeholder="请输入该问题相关的关联词（例如：'招生简章 招生章程 招生计划书 招生宣传册'）" style="width: 100%" />
        </el-form-item>
        <el-form-item label="回答内容" prop="answer">
          <el-input v-model.trim="editForm.answer" type="textarea" :autosize="{ minRows: 4, maxRows: 10 }"
            placeholder="请输入回答" style="width: 100%" />
        </el-form-item>

        <el-form-item label="是否启用">
          <el-radio-group v-model="editForm.dis_flag" size="small" style="width: 50%;">
            <el-radio :label="0">启用</el-radio>
            <el-radio :label="1">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model.trim="editForm.remark" size="small" />
        </el-form-item>
        <el-form-item label="排序">
          <el-input-number v-model="editForm.sort" size="small" :min="1" label="排序号"></el-input-number>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button size="mini" icon="el-icon-document-checked" type="primary" @click="onSubmit('adminUserInfo')">保存问答
        </el-button>
      </div>
    </el-dialog>

  </div>
</template>
<script>
export default {
  name: 'adviceList',
  components: {},
  data() {
    return {
      searchForm: {},//查询
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      visible: false,
      modalTitle: '添加',
      editForm: {
        issue: '',
        answer: '',
        sort: 99,
        remark: '',
        dis_flag: 0,
      },
      rules: {
        issue: [{ required: true, message: '请输入问题标题', trigger: 'blur' }],
        answer: [{ required: true, message: '请输入问题回答', trigger: 'blur' }],
      },
      //table
      tabMapOptions: [{ label: '问答列表', key: 'CN' }],
      activeName: 'CN',
      tableData: [],
      page: {
        pageIndex: 1,
        pageSize: 10,
        pageSizes: [10, 50],
        total: 0
      },
    }
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      let params = Object.assign({}, { ...this.searchForm }, {
        page: this.page.pageIndex,
        pagesize: this.page.pageSize,
      })
      this.$http.post('/api/t_sch_smart_answer_list', params).then(res => {
        console.log(res)
        this.tableData = res.data.data
        this.page.total = res.data.page.count
      })
    },
    onSearch() {
      this.tableData = [];
      this.page.pageIndex = 1;
      this.getList();
    },

    onReset() {
      this.searchForm = {};
      this.tableData = [];
      this.page.pageIndex = 1;
      this.getList();
    },
    onAdd() {
      this.visible = true;
      this.editForm = {
        issue: '',
        answer: '',
        sort: 99,
        remark: '',
        dis_flag: 0,
      }
      this.modalTitle = '添加'
    },
    onModify(row) {
      this.visible = true
      row.dis_flag = parseInt(row.dis_flag)
      this.editForm = row
      this.modalTitle = '修改'
    },
    onDel(item) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post("/api/t_sch_smart_answer_delete", {
          id: item.id
        }).then(res => {
          this.$message.success('删除成功');
          this.getList();
        })
      }).catch(() => {

      });
    },
    onSubmit(formName) {
      let _this = this
      this.editForm.dis_flag = parseInt(this.editForm.dis_flag)
      _this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$http.post('/api/t_sch_smart_answer_edit', this.editForm).then(res => {
            this.$message.success('保存成功')
            this.getList();
            this.visible = false;
          })
        }
      })
    },
    handlePageChange(e) {
      this.page.pageIndex = e
      this.getList()
    },
    handleSizeChange(e) {
      this.page.pageSize = e
      this.getList()
    },
  }
}
</script>

<style>
.searchBox .el-form-item {
  margin-bottom: 0;
}
</style>
<style scoped lang="less" type="text/less">
.demo-table-expand {
  font-size: 0;
}

.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}

.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}

.app-container {
  .treeContainer {
    height: 100%;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .12), 0 0 6px 0 rgba(0, 0, 0, .04);
    border: 1px solid rgba(220, 223, 230, 0.6);

    .treeTitle {
      height: 40px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: #409eff;
      padding-left: 6px;
    }
  }
}
</style>
